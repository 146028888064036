var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "sygni-breadcrumbs"
  }, _vm._l(_vm.items, function (item) {
    return _c('li', {
      key: item.label,
      staticClass: "sygni-breadcrumbs__item",
      on: {
        "click": function click($event) {
          return _vm.$router.push(item.route);
        }
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }