var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['sygni-tags', _vm.type == 'outline' ? 'sygni-tags--outline' : '']
  }, [_vm.label ? _c('p', {
    staticClass: "sygni-tags__label label"
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _c('div', {
    staticClass: "sygni-tags__list"
  }, _vm._l(_vm.items, function (item) {
    return _c('div', {
      key: item,
      staticClass: "sygni-tags__item"
    }, [_vm._v(_vm._s(item))]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }