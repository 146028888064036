































































import Vue from 'vue'
import Component from 'vue-class-component'
import InsightFilters from '../components/InsightFilters.vue'
import PostBox from '../components/PostBox.vue';
import SygniBreadcrumbs from '@/components/layout/SygniBreadcrumbs.vue';
import NavigationPost from '../components/NavigationPost.vue';
import AuthorPanel from '../components/AuthorPanel.vue';
import SygniTags from '@/components/layout/SygniTags.vue';
import { BPagination, BSpinner } from 'bootstrap-vue';
import { InsightData } from '../store/types';

@Component({
  components: { InsightFilters, PostBox, BPagination, BSpinner, SygniBreadcrumbs, SygniTags, NavigationPost, AuthorPanel },
})
export default class InsightModule extends Vue {
  isLoading: boolean = false;
  insight: InsightData = null;

  async getInsight() {
    const insight = await this.$store.dispatch('insights/getInsight', this.$route.params.slug);
    this.insight = insight;
    this.isLoading = false;
  }

  goToPreviousInsight() {
    console.log('go to previous insight');
  }

  goToNextInsight() {
    console.log('go to next insight');
  }

  get breadcrumbs() {
    // const title = this.insight.title ? this.insight.title : undefined;
    return [
      'Insights',
      // title
    ]
  }
  
  async beforeMount() {
    this.isLoading = true;
    try {
      await this.$store.dispatch('profile/getDictionaries');
      await this.getInsight();
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.isLoading = false;
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
      this.$router.push({ name: 'insights' })
    }
  }
}
