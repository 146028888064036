








import Vue from 'vue';
import Component from 'vue-class-component'
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  components: { SygniRoundedButton },
})
export default class NavigationPost extends Vue {
  @Prop() label!: string;
  @Prop() title!: string;
}
