var render = function () {
  var _vm$insight, _vm$insight2, _vm$insight3, _vm$insight4, _vm$insight5, _vm$insight6, _vm$insight7, _vm$insight8, _vm$insight9, _vm$insight10;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "insight",
    staticClass: "insight"
  }, [_c('div', {
    class: ['insight__loader', _vm.isLoading ? 'active' : '']
  }, [_c('div', {
    staticClass: "spinner"
  }, [_c('b-spinner')], 1)]), _c('div', {
    staticClass: "insight__container"
  }, [_c('div', {
    staticClass: "insight__main"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-10 offset-1 col-md-12 offset-md-0 col-lg-10 offset-lg-1"
  }, [_c('sygni-breadcrumbs', {
    attrs: {
      "items": [{
        route: {
          name: 'insights'
        },
        label: 'Insights'
      }, {
        route: {
          name: 'insight-by-slug',
          params: {
            slug: (_vm$insight = _vm.insight) === null || _vm$insight === void 0 ? void 0 : _vm$insight.slug
          }
        },
        label: (_vm$insight2 = _vm.insight) === null || _vm$insight2 === void 0 ? void 0 : _vm$insight2.title
      }]
    }
  }), _c('div', {
    staticClass: "insight__thumbnail"
  }, [_c('img', {
    attrs: {
      "src": (_vm$insight3 = _vm.insight) === null || _vm$insight3 === void 0 ? void 0 : _vm$insight3.photoHeader,
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "insight__info"
  }, [_c('div', {
    staticClass: "insight__category"
  }, [_vm._v(_vm._s((_vm$insight4 = _vm.insight) === null || _vm$insight4 === void 0 ? void 0 : _vm$insight4.tags.join(', ')))]), _c('p', {
    staticClass: "insight__date"
  }, [_vm._v("Posted: " + _vm._s(_vm._f("dateWithoutTime")((_vm$insight5 = _vm.insight) === null || _vm$insight5 === void 0 ? void 0 : _vm$insight5.publishedAt)))])]), _c('h1', {
    staticClass: "insight__header"
  }, [_vm._v(_vm._s((_vm$insight6 = _vm.insight) === null || _vm$insight6 === void 0 ? void 0 : _vm$insight6.title))]), _c('p', {
    staticClass: "insight__excerpt"
  }, [_vm._v(_vm._s((_vm$insight7 = _vm.insight) === null || _vm$insight7 === void 0 ? void 0 : _vm$insight7.excerpt))]), _c('div', {
    staticClass: "insight__content",
    domProps: {
      "innerHTML": _vm._s((_vm$insight8 = _vm.insight) === null || _vm$insight8 === void 0 ? void 0 : _vm$insight8.content)
    }
  }), _c('div', {
    staticClass: "insight__tags"
  }, [_c('sygni-tags', {
    attrs: {
      "label": "INDUSTRIES",
      "items": (_vm$insight9 = _vm.insight) === null || _vm$insight9 === void 0 ? void 0 : _vm$insight9.tags
    }
  })], 1)], 1)])])]), _c('div', {
    staticClass: "insight__sidebar"
  }, [_c('author-panel', {
    attrs: {
      "author": (_vm$insight10 = _vm.insight) === null || _vm$insight10 === void 0 ? void 0 : _vm$insight10.author
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }