import { render, staticRenderFns } from "./insightModule.vue?vue&type=template&id=e6ac08e8&scoped=true&"
import script from "./insightModule.vue?vue&type=script&lang=ts&"
export * from "./insightModule.vue?vue&type=script&lang=ts&"
import style0 from "./insightModule.vue?vue&type=style&index=0&id=e6ac08e8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e6ac08e8",
  null
  
)

export default component.exports