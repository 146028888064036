









import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';

@Component
export default class SygniBreadcrumbs extends Vue {
  @Prop() label?: string;
  @Prop() items!: Array<string>;
  @Prop({default: 'normal'}) type: 'normal' | 'outline';
}
