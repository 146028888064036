var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "navigation-post"
  }, [_c('p', {
    staticClass: "navigation-post__label"
  }, [_vm._v(_vm._s(_vm.label))]), _c('h2', {
    staticClass: "navigation-post__title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('sygni-rounded-button', {
    staticClass: "navigation-post__btn outline gn-secondary",
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm._v("Read more")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }