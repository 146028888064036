
























import Vue from 'vue';
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';
import { AuthorData } from '../store/types';
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';

@Component({
  components: { SygniContainerTitle },
})
export default class AuthorPanel extends Vue {
  @Prop() author!: AuthorData;
}
