var render = function () {
  var _vm$author, _vm$author2, _vm$author3, _vm$author3$firstName, _vm$author4, _vm$author5, _vm$author6, _vm$author7;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "author-panel"
  }, [_c('div', {
    staticClass: "author-panel__inner"
  }, [_c('div', {
    staticClass: "author-info"
  }, [_c('div', {
    staticClass: "author-info__image"
  }, [(_vm$author = _vm.author) !== null && _vm$author !== void 0 && _vm$author.photo ? _c('img', {
    attrs: {
      "src": (_vm$author2 = _vm.author) === null || _vm$author2 === void 0 ? void 0 : _vm$author2.photo,
      "alt": ""
    }
  }) : _c('div', {
    staticClass: "char"
  }, [_vm._v(" " + _vm._s((_vm$author3 = _vm.author) === null || _vm$author3 === void 0 ? void 0 : (_vm$author3$firstName = _vm$author3.firstName) === null || _vm$author3$firstName === void 0 ? void 0 : _vm$author3$firstName.slice(0, 1)) + " ")])]), _c('p', {
    staticClass: "author-info__label"
  }, [_vm._v("Author:")]), _c('p', {
    staticClass: "author-info__name"
  }, [_vm._v(_vm._s("".concat((_vm$author4 = _vm.author) === null || _vm$author4 === void 0 ? void 0 : _vm$author4.firstName, " ").concat((_vm$author5 = _vm.author) === null || _vm$author5 === void 0 ? void 0 : _vm$author5.surname)))]), _c('p', {
    staticClass: "author-info__title"
  }, [_vm._v(_vm._s((_vm$author6 = _vm.author) === null || _vm$author6 === void 0 ? void 0 : _vm$author6.professionalHeadline))])]), _c('div', {
    staticClass: "author-panel__body"
  }, [_c('sygni-container-title', [_vm._v("About")]), _c('div', {
    staticClass: "author-panel__content"
  }, [_c('p', [_vm._v(_vm._s((_vm$author7 = _vm.author) === null || _vm$author7 === void 0 ? void 0 : _vm$author7.professionalDescription))])])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }